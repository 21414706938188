import React, { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Link } from 'gatsby';
import Select from 'react-select';
import { GiCardboardBox } from 'react-icons/gi';
import { MdLocalGroceryStore } from 'react-icons/md';

import { Meta } from '../components/Meta';
import { Main } from '../components/Main';
import { Listing } from '../components/Listing';
import { slovakiaOption, CustomSelectOption } from '../components/Item/CustomSelectOption';
import { isBrowser } from '../utils';
import { merge, unique } from '../utils/array';
import { routes, sectors as allSectors } from '../utils/constants';

import css from './index.module.css';

export default () => {
  const [areas, setAreas] = useState([]);
  const [sectors, setSectors] = useState([]);

  const [values, loading, error] = useCollectionData(
    isBrowser() ? firebase.firestore().collection('services').where('published', '==', true) : null,
    {
      idField: 'id',
    }
  );

  const areaOptions =
    values && values.length > 0
      ? unique(merge(values.map((item) => item.area)))
          .filter(Boolean)
          .filter((item) => item !== slovakiaOption.value)
          .map((item) => ({
            value: item,
            label: item,
          }))
      : [];

  areaOptions.unshift(slovakiaOption);

  const getResults = (values, areas, sectors) => {
    if (!values) {
      return [];
    }

    return values.filter(
      (item) =>
        (areas.length > 0 ? areas.some((area) => (item.area || []).includes(area)) : true) &&
        (sectors.length > 0 ? sectors.some((sector) => (item.sectors || []).includes(sector)) : true)
    );
  };

  return (
    <Main>
      <Meta />
      <section className={css.banner}>
        <h2>
          <strong>PODÁVAME POMOCNÚ RUKU v čase pandémie koronavírusu</strong>
        </h2>

        <div className={css.cols}>
          <div className={css.col}>
            <p className={css.icon}>
              <GiCardboardBox size="3rem" />
            </p>
            <h3>Pre firmy a živnostníkov</h3>
            <p>
              Máte tovar skladom? Ste kamenná predajňa? A viete zabezpečiť predaj tovaru, rozvoz alebo iné služby v
              súlade s epidemiologickými opatreniami? <strong>Inzerujte ZADARMO, podporíme Váš biznis!</strong>
            </p>
          </div>

          <div className={css.col}>
            <p className={css.icon}>
              <MdLocalGroceryStore size="3rem" />
            </p>
            <h3>Pre zákazníkov</h3>
            <p>
              Patríte do rizikovej skupiny? Ste v karanténe? Nenašli ste tovar na e-shope a v kamennej predajni je
              skladom? Potrebujete dovoz tovaru alebo inú službu?{' '}
              <strong>Podporme spoločne živnostníkov, kamenné predajne a malé e-shopy.</strong>
            </p>
          </div>
        </div>

        <p>
          <Link to={routes.project}>Pre koho je stránka určená, ako vznikol projekt, ako inzerovať a nakúpiť?</Link>
        </p>
        <p>
          <small>
            Naša stránka nesprostredkúva priamy predaj výrobkov. Prezrite si inzerciu a oslovte predajcu prostredníctvom
            kontaktu, ktorý uviedol v inzeráte.
          </small>
        </p>
      </section>

      <section className={css.filters}>
        <div className={css.filter}>
          <label htmlFor="sectors">Hľadať podľa sektora</label>
          <Select
            inputId="sectors"
            placeholder="Vybrať sektor"
            noOptionsMessage={(_inputValue) => 'Žiadne položky na výber'}
            isMulti={true}
            options={allSectors.map((name) => ({
              value: name,
              label: name,
            }))}
            isOptionDisabled={(option) =>
              values ? !merge(values.map((value) => value.sectors || [])).includes(option.value) : true
            }
            isLoading={loading}
            onChange={(selectedSectors) =>
              setSectors(selectedSectors ? selectedSectors.map((selectedSector) => selectedSector.value) : [])
            }
          />
        </div>

        <div className={css.filter}>
          <label htmlFor="area">
            Hľadať podľa mesta <small>(dostupné len tie, v ktorých je inzerované)</small>
          </label>
          <Select
            inputId="area"
            placeholder="Vybrať mesto"
            noOptionsMessage={(_inputValue) => 'Žiadne položky na výber'}
            isMulti={true}
            components={{ Option: CustomSelectOption }}
            options={areaOptions}
            isLoading={loading}
            onChange={(selectedAreas) =>
              setAreas(selectedAreas ? selectedAreas.map((selectedArea) => selectedArea.value) : [])
            }
          />
        </div>
      </section>

      <Listing values={getResults(values, areas, sectors)} loading={loading} error={error} />
    </Main>
  );
};
